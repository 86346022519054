import ScopeInfoModal from '@/components/molecules/Modals/ScopeInfoModal'
import { TreeNodeLink } from '@/openapi'
import theme from '@/theme'
import { FormControlLabel, IconButton, makeStyles } from '@material-ui/core'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { CustomTooltip } from '../CustomTooltip'
import BoxArrowUpIcon from '../Icon/svg/BoxArrowUpIcon'
import CheckedIcon from '../Icon/svg/CheckedIcon'
import FAQIcon from '../Icon/svg/FAQIcon'
import InfoIcon from '../Icon/svg/InfoIcon'
import SquareIcon from '../Icon/svg/SquareIcon'

const useStyle = makeStyles({
    root: {
        '& > span': {
            fontWeight: 300,
            fontSize: '12px',
        },
        marginRight: 0,
    },
    font14: {
        '& > span': {
            fontSize: '14px',
        },
    },
    checkContainer: {
        position: 'relative',
        width: 'fit-content',
    },
    link: {
        display: 'block',
        marginLeft: 20,
        color: theme.colors.primaryN,
    },
    arrowIcon: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            bottom: 0,
            right: 2,
            width: 15,
            content: '""',
            borderBottom: `1px solid ${theme.colors.primaryN}`,
        },
    },
    checkedLabel: {
        fontWeight: 600,
    },
})

export const StyledCheckbox = withStyles({
    root: {
        color: theme.colors.grey,
        '&&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checked: {
        color: theme.colors.accent,
    },
})(({ showLine, ...props }: CheckboxProps & { showLine?: boolean }) => {
    const useStyles = makeStyles({
        checkbox: {
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '-10px',
                bottom: '50%',
                left: '-15px',
                width: 24,
                borderLeft: '3px solid #c2c2c2',
                borderBottom: '3px solid #c2c2c2',
            },
        },
    })
    const classes = useStyles()
    return (
        <Checkbox
            checkedIcon={<CheckedIcon />}
            icon={<SquareIcon />}
            color="default"
            disableRipple
            disableFocusRipple
            className={showLine ? classes.checkbox : ''}
            {...props}
        />
    )
})

export default function StyledCheckboxWithLabel(props: {
    checkboxProps: CheckboxProps
    sizeLarge?: boolean
    hideLine?: boolean
    showInfo?: boolean
    description?: string
    link?: TreeNodeLink
    modalLinks?: TreeNodeLink[]
}) {
    const [checked, setChecked] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const { root, font14, checkContainer, link, ...classes } = useStyle()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    const splitLabel = props.checkboxProps.name?.split('（')
    const descLabel = splitLabel?.slice(1).join('（') || ''

    if (!splitLabel) return null

    return (
        <div className={checkContainer}>
            <FormControlLabel
                className={props.sizeLarge ? `${root} ${font14}` : root}
                control={
                    <StyledCheckbox
                        checked={checked}
                        onChange={handleChange}
                        name={props.checkboxProps.name}
                        showLine={props.sizeLarge && !props.hideLine}
                        {...props.checkboxProps}
                    />
                }
                label={
                    <div className={props.checkboxProps.checked ? classes.checkedLabel : ''}>
                        {splitLabel[0]} {descLabel && <div style={{ marginLeft: 15 }}>（{descLabel}</div>}
                    </div>
                }
            />
            {props?.description && (
                <>
                    <CustomTooltip
                        title={<span dangerouslySetInnerHTML={{ __html: props.description }}></span>}
                        styleTooltip={{ maxWidth: 'unset' }}
                    >
                        <IconButton
                            style={{
                                padding: 0,
                                position: 'absolute',
                                top: 10,
                                right: -30,
                            }}
                        >
                            <FAQIcon />
                        </IconButton>
                    </CustomTooltip>
                </>
            )}
            {props?.link && (
                <a href={props.link.url} target="_blank" className={link}>
                    {props.link.text} &nbsp;
                    <span className={classes.arrowIcon}>
                        <BoxArrowUpIcon />
                    </span>
                </a>
            )}
            {props?.showInfo && (
                <>
                    <IconButton onClick={() => setShowModal(true)}>
                        <InfoIcon />
                    </IconButton>
                    <ScopeInfoModal open={showModal} onClose={() => setShowModal(false)} />
                </>
            )}
        </div>
    )
}
