import DeleteIcon from '@/components/atoms/Icon/svg/DeleteIcon'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import MemoEnteredIcon from '@/components/atoms/Icon/svg/MemoEnteredIcon'
import MemoUnEnteredIcon from '@/components/atoms/Icon/svg/MemoUnEnteredIcon'
import InputField from '@/components/atoms/InputField'
import { useInputTreeContext } from '@/contexts/InputTree'
import { makeMonth, siteActivityApi } from '@/ghgApi'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { EmissionFactor } from '@/openapi/api'
import theme from '@/theme'
import { thousandSeparator } from '@/utils/numHelper'
import { InputTreeActivity, InputTreeLeafNode } from '@/utils/tree'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { IconButton } from '@material-ui/core'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import styled from 'styled-components'
import { ItemInput } from '.'
import EditMemoModal from './EditMemoModal'

const ErrorText = styled.span`
    color: ${theme.colors.error};
    margin: 3px 0 0 23px;
`

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            color: theme.colors.black,
            fontWeight: 600,
            fontSize: 14,
            paddingLeft: 10,
            '&:first-child': {
                borderLeft: `dashed ${theme.colors.grey} 1px`,
                borderRight: 'none',
            },
            '&:not(first-child)': {
                borderRight: `dashed ${theme.colors.grey} 1px`,
            }
        },
        body: {
            fontWeight: 300,
            fontSize: 14,
            padding: '15px 10px',
            height: '2em',
            '&:first-child': {
                borderLeft: `dashed ${theme.colors.grey} 1px`,
                borderRight: 'none',
            },
            '&:not(first-child)': {
                borderRight: `dashed ${theme.colors.grey} 1px`,
            }
        },
    }),
)(TableCell)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            borderBottom: 'none',
            '&:nth-child(even)': {
                backgroundColor: theme.colors.lightGray,
            },
        },
    }),
)(TableRow)

const ActivityRow = (
    props: {
        emissionFactor: EmissionFactor
    } & ItemInput,
) => {
    const { inputTreeState, setInputTreeState } = useInputTreeContext()
    const { storeState, inputScopeState } = useStore()
    const { emissionFactorTableNodes } = useStore()
    const siteId = useMemo(() => storeState.selectedSite?.id, [storeState.selectedSite?.id])
    const [sessionDate] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const [showModal, setShowModal] = useState(false)
    const [memo, setMemo] = useState("")
    const [value, setValue] = useState('')
    const [result, setResult] = useState('0')
    const [error, setError] = useState<String>()
    const preQuantity =
        props.prevActivities.find(
            (p) => p.emissionFactorId === props.emissionFactor.id && p.categoryEmissionFactorTableId === props.leaf.id,
        )?.quantity || 0
    const classes = useStyles()

    useEffect(() => {
        const activity = props.leaf.activities.find((a) => a.emissionFactorId == props.emissionFactor.id)
        const initialMemo = activity?.memo
        setMemo(initialMemo ? initialMemo : '')
        const initialValue = activity?.quantity
        const hasInitialValue = initialValue || initialValue === 0
        setValue(hasInitialValue ? thousandSeparator(String(initialValue)) : '')
        const newResult = hasInitialValue ? initialValue * props.emissionFactor.value : 0
        const total = newResult !== 0 ? newResult.toFixed(3) : newResult.toString()
        setResult(total)
    }, [props.leaf.activities])

    const handleInputChange = (value: string) => {
        const ZENKAKU_NUMBERS = /[\uFF10-\uFF19]/

        // Start: Validation
        // if (!value) {
        //     setError('活動量を入力')
        //     setValue('')
        //     return
        // }

        if (ZENKAKU_NUMBERS.test(value)) {
            setError('半角数字を入力してください')
            return
        }

        const newValue = Number(value)
        if (isNaN(newValue)) {
            setError('半角数字を入力してください')
            return
        }

        const [integerPart, decimalPart] = value.split('.')
        if (integerPart?.length > 12) {
            setError('最大12桁まで')
            return
        }
        if (decimalPart?.length > 3) {
            setError('小数点以下は3桁まで')
            return
        }

        if (value.charAt(0) === '0' && value.charAt(1) === '0') {
            setValue('0')
            return
        }

        setError('')
        // End: Validation

        const updateActivity = props.leaf.activities.find((a) => a.emissionFactorId == props.emissionFactor.id)
        if (updateActivity) {
            if (value === '') updateActivity.quantity = null
            else updateActivity.quantity = newValue
        } else {
            const newActivity: InputTreeActivity = {
                categoryEmissionFactorTableId: props.leaf.id,
                emissionFactorId: props.emissionFactor.id,
                quantity: newValue,
            }
            props.leaf.activities.push(newActivity)
        }
        setInputTreeState({ ...inputTreeState, tree: props.leaf.root, complete: props.leaf.root.isComplete })

        const newResult = props.emissionFactor.value * newValue
        const total = newResult !== 0 ? newResult.toFixed(3) : newResult.toString()
        setValue(thousandSeparator(value))
        setResult(total)
    }

    const memoCharLimit = 24
    const getToolTipContent = () => {
        if (isEnteredMemo()) {
            if (memo.length > memoCharLimit) {
                return memo.substring(0, memoCharLimit) + '...'
            } else {
                return memo
            }
        }
        return 'メモを追加'
    }

    const isEnteredMemo = () => {
        return memo && memo.trim() !== ''
    }

    const getEditMemoModalTitle = () => {
        const eFTableNode = emissionFactorTableNodes.find((eF) => eF.emissionFactorTableId === props.leaf.emissionFactorTableId && eF.id === props.leaf.id)
        return `${props.scope3categoryName ? `${props.scope3categoryName} > ` : ''}${eFTableNode?.parent ? `${eFTableNode.parent} > ` : ''}${eFTableNode?.name ? `${eFTableNode?.name} > ` : ''}${props.emissionFactor.name}`
    }

    const handleEditMemoClick = () => {
        if (!showModal) {
            setShowModal(true)
        }
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleSave = (memo: string) => {
        if (!siteId) {
            return
        }
        saveActivity(memo)
    }

    const handleDelete = () => {
        if (!siteId) {
            return
        }
        saveActivity('')
    }

    async function saveActivity(savingMemo: string) {
        if (!storeState.selectedSite) {
            return
        }
        const currentMonth = makeMonth(
            sessionDate?.year || inputScopeState.date.year,
            sessionDate?.month || inputScopeState.date.month,
        )
        const activity = props.leaf.activities.find((a) => a.emissionFactorId == props.emissionFactor.id)

        try {
            await siteActivityApi.saveSiteActivity(
                storeState.selectedSite.id,
                currentMonth,
                {
                    ...activity,
                    quantity: activity?.quantity || 0,
                    memo: savingMemo,
                }
            )
            setMemo(savingMemo)

            // 該当するツリーノードのActicityを更新する
            const selectedIndex = inputTreeState.selected.findIndex((n) => n.id === props.leaf.id)
            const newSelected = inputTreeState.selected[selectedIndex]
            const activityIndex = newSelected.activities.findIndex((a) => a.emissionFactorId == props.emissionFactor.id)
            const updatedActicity = {
                ...newSelected.activities[activityIndex],
                memo: savingMemo,
            }
            const newActivities = [
                ...newSelected.activities.slice(0, activityIndex),
                { ...newSelected.activities[activityIndex], ...updatedActicity },
                ...newSelected.activities.slice(activityIndex + 1)
            ]
            newSelected.activities = [...newActivities]
            setInputTreeState({
                ...inputTreeState,
                ...newSelected,
                complete: props.leaf.root.isComplete
            })
        } catch (err) {
            throw Error('failed to save activity.')
        }
    }

    const handleRemoveClick = () => {
        const index = inputTreeState.selected.findIndex((x: InputTreeLeafNode) => x.id === props.leaf.id)
        inputTreeState.selected[index].selectedEmissionFactorIds.delete(props.emissionFactor.id)
        const newSelected = inputTreeState.selected[index]
        const newActivities = newSelected.activities.filter(
            (x: InputTreeActivity) => x.emissionFactorId !== props.emissionFactor.id,
        )
        newSelected.activities = [...newActivities]
        setInputTreeState({
            ...inputTreeState,
            ...newSelected,
            complete: props.leaf.root.isComplete,
        })
    }

    return (
        <StyledTableRow key={`input-table-row-${props.emissionFactor.id}`}>
            <StyledTableCell component="th" scope="row">
                {props.emissionFactor.name}
            </StyledTableCell>
            <StyledTableCell align="center">
                <Tooltip
                    id={`tooltip-memo-${props.emissionFactor.id}`}
                    className={isEnteredMemo() ? classes.toolTipEnteredMemo : classes.toolTipUnEnteredMemo}
                    classNameArrow={isEnteredMemo() ? classes.toolTipArrowEntered : '' } />
                <IconButton onClick={handleEditMemoClick} style={{ padding: '0' }} data-tooltip-id={`tooltip-memo-${props.emissionFactor.id}`} data-tooltip-content={getToolTipContent()}>
                    {isEnteredMemo() ? <MemoEnteredIcon/> : <MemoUnEnteredIcon/> }
                </IconButton>
                <EditMemoModal
                    title={getEditMemoModalTitle()}
                    initialMemo={memo}
                    open={showModal}
                    onSave={handleSave}
                    onDelete={handleDelete}
                    onClose={handleClose} />
            </StyledTableCell>
            <StyledTableCell align="left">
                {
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ width: 13 }}>{error && <ErrorIcon />}</span>
                                <InputField
                                    style={{ width: 200, marginLeft: 7 }}
                                    classes={{}}
                                    value={value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        return handleInputChange(e.target.value.split(',').join(''))
                                    }}
                                    error={!!error}
                                    // onFocus={() => setError('')}
                                />
                            </div>
                        </div>
                        <div style={{ flex: 1, margin: '5px 0 0 10px' }}>
                            <span>{props.emissionFactor.unit}</span>
                            <span style={{ marginLeft: 30, color: '#696969', fontSize: 10, fontWeight: 300 }}>
                                前月：{thousandSeparator(preQuantity.toString())}
                                {props.emissionFactor.unit}
                            </span>
                        </div>
                    </div>
                }
                {error && <ErrorText>{error}</ErrorText>}
            </StyledTableCell>
            <StyledTableCell align="right" style={{ paddingLeft: 30 }}>
                {thousandSeparator(result)}
            </StyledTableCell>
            <StyledTableCell align="center">
                <IconButton onClick={handleRemoveClick} style={{ padding: '0' }}>
                    <DeleteIcon />
                </IconButton>
            </StyledTableCell>
        </StyledTableRow>
    )
}

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        margin: '30px 0',
    },
    tableHead: {
        backgroundColor: theme.colors.lightGray,
        borderTop: `1px solid ${theme.colors.borderColor}`,
    },
    toolTipArrowEntered: {
      borderBottom: '1px solid #42526E',
      borderRight: '1px solid #42526E',
    },
    toolTipEnteredMemo: {
      textAlign: 'left',
      color: '#000',
      backgroundColor: '#FFFFFF',
      border: '1px solid #42526E',
      maxWidth: '100px',
      maxHeight: '100px',
      wordBreak: 'break-all',
    },
    toolTipUnEnteredMemo: {
      backgroundColor: '#42526E',
      color: '#FFFFFF',
    },
})

/**
 * https://github.com/mui-org/material-ui/issues/1911
 */
export default function ItemInputForm(props: ItemInput) {
    const classes = useStyles()
    const [showTable, setShowTable] = useState(false)
    useEffect(() => {
        const isHasEf = props.leaf.selectedEmissionFactorIds.size !== 0
        const isMapingEmissionFactor = props.leaf.emissionFactors.some((eF) =>
            props.leaf.activities.map((activity) => activity.emissionFactorId).includes(eF.id),
        )
        if (isMapingEmissionFactor && isHasEf) {
            setShowTable(true)
        } else {
            setShowTable(false)
        }
    }, [props.leaf.selectedEmissionFactorIds, props.leaf.emissionFactors, props.leaf.activities])

    if (showTable) {
        return (
            <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead classes={{ root: classes.tableHead }}>
                        <TableRow>
                            <StyledTableCell width={400} align="center">
                                項目
                            </StyledTableCell>
                            <StyledTableCell width={100} align="center">
                            </StyledTableCell>
                            <StyledTableCell align="center" width={450}>
                                活動量
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ paddingLeft: 30, minWidth: 186 }}>
                                算定排出量（t-CO&#8322;e）
                            </StyledTableCell>
                            <StyledTableCell width={100} align="center">
                                削除
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.leaf.emissionFactors
                            .filter((eF) => props.leaf.selectedEmissionFactorIds.has(eF.id))
                            .map((eF) => (
                                <ActivityRow
                                    key={`${props.leaf.id}-${eF.id}`}
                                    leaf={props.leaf}
                                    emissionFactor={eF}
                                    prevActivities={props.prevActivities}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return (
            <span style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
                入力する項目を選択、またはCSVをアップロードしてください。
            </span>
        )
    }
}
