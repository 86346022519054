import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import CustomModal from '@/components/atoms/CustomModal'
import DownloadFileIcon from '@/components/atoms/Icon/svg/DownloadFileIcon'
import UploadFileIcon from '@/components/atoms/Icon/svg/UploadFileIcon'
import Line from '@/components/atoms/Line'
import { useInputTreeContext } from '@/contexts/InputTree'
import { Category, EmissionFactor } from '@/openapi'
import { InputTreeActivity, InputTreeLeafNode, InputTreeNode } from '@/utils/tree'
import useStore from '@/zustand/sotre'
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import * as Encoding from 'encoding-japanese'
import * as FileSaver from 'file-saver'
import Papa, { ParseConfig } from 'papaparse'
import React, { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

type EmissionFactorForDownload = EmissionFactor & {
    type: InputTreeNode['name']
    calculationMethod: InputTreeLeafNode['name']
    categoryId: Category['id']
    emissionFactorTableId: number
    quantity: number
    memo: string
}

const Input = styled.input`
    display: none;
`
const useStyle = makeStyles({
    container: {
        display: 'flex',
        columnGap: 30,
        margin: '50px 0',
    },
    csvWrapper: {
        width: 340,
    },
    csvTitle: {
        fontSize: 18,
        fontWeight: 300,
    },
    csvBtn: {
        width: 164,
        height: 38,
        fontSize: 16,
        fontWeight: 300,
    },
    formBtnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 30,
    },
})

export default function CSVHandler(props: { scopeNumber: number }) {
    const [showModal, setShowModal] = useState(false)
    const { inputTreeState, setInputTreeState } = useInputTreeContext()
    const { emissionFactorTableNodes, setMessage } = useStore()
    const [emissionFactorsForDownload, setEmissionFactorsForDownload] = useState<Array<EmissionFactorForDownload>>([])
    const classes = useStyle()

    const parseConfig: ParseConfig = {
        skipEmptyLines: true,
    }

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0]
        if (file) {
            let reader = new FileReader()
            reader.onload = onLoadCSV

            reader.readAsArrayBuffer(file)

            // 同じファイルをアップできるため
            e.target.value = null
        }

        function onLoadCSV(e: ProgressEvent<FileReader>) {
            const quantityIndex = 3
            const eFIdIndex = 5
            const categoryIndex = 6
            const memoIndex = 7

            if (e && e.target) {
                const codes = new Uint8Array(e.target.result as ArrayBuffer)
                const encoding = Encoding.detect(codes)
                const unicodeString = Encoding.convert(codes, {
                    to: 'UNICODE',
                    from: encoding as Encoding.Encoding,
                    type: 'string',
                })

                const data = Papa.parse(unicodeString, parseConfig).data
                const headlessData: string[][] = data.slice(1).map((d: string[]) => {
                    const result = d.map((inner, i) => {
                        if (i === 1) {
                            const replaceName: string = inner.split(' > ').reverse().shift() || inner
                            return replaceName
                        }
                        return inner
                    })
                    return result
                })

                const errors = validate(headlessData)
                if (!errors.length) {
                    importData(headlessData)
                } else {
                    setMessage({ message: errors[0], type: 'error' })
                    errors.forEach((error) => console.warn(error))
                }
            }

            function validate(headlessData: Array<any>): Array<string> {
                const errors = new Array<string>()
                // 活動量がnumberであること
                headlessData.forEach((row, index) => {
                    const quantity = row[quantityIndex]
                    if (isNaN(Number(quantity))) errors.push(`${index + 1}番目：活動量は番号ではありません`)
                    if (Number(quantity) < 0) errors.push(`${index + 1}番目：活動量がマイナスの値になってます`)

                    const [integerPart, decimalPart] = quantity.split('.')
                    if (integerPart?.length > 12) errors.push(`${index + 1}番目：最大12桁まで`)
                    if (decimalPart?.length > 3) errors.push(`${index + 1}番目：小数点以下3桁まで`)
                })

                const dataCatagories = new Set(headlessData.map((row) => row[1]))
                const selectedCatagories = new Set(inputTreeState.selected.map((node) => node.name))
                const categoriesAreEqual =
                    dataCatagories.size === selectedCatagories.size &&
                    [...dataCatagories].every((c) => selectedCatagories.has(c))
                if (!categoriesAreEqual && selectedCatagories.size !== 0) {
                    //少なくとも 1 つのカテゴリが選択されている場合、この条件がチェックされます
                    errors.push('CSV項目は選択された項目と一致していません。')
                }

                return errors
            }

            function importData(headlessData: Array<any>) {
                const activities: Array<InputTreeActivity> = []
                headlessData.forEach((d) => {
                    const quantity = Number(d[quantityIndex])
                    const categoryId = Number(d[categoryIndex])
                    const memo = d[memoIndex] as string
                    const eF_id = Number(d[eFIdIndex])
                    if ((quantity || memo) && eF_id) {
                        const eF = emissionFactorsForDownload.find(
                            (eF) => eF.id === eF_id && eF.categoryId === categoryId,
                        )
                        if (eF)
                            activities.push({
                                emissionFactorId: eF.id,
                                categoryEmissionFactorTableId: eF.categoryId,
                                parentName: eF.type,
                                quantity,
                                memo: memo.replace(/\\n/g, '\n')
                            })
                    }
                })
                if (Array.isArray(activities)) {
                    const tree = inputTreeState.tree
                    tree.setAllActivities(activities)
                    setInputTreeState({
                        ...inputTreeState,
                        tree,
                        complete: tree.isComplete,
                        selected: tree.getAllSelected(),
                    })
                }
            }
        }
    }

    const getAllEFFactor = () => {
        const allEFFactor: EmissionFactorForDownload[][] = []
        const flattenEf = (inputTreeNode: InputTreeNode[]) => {
            inputTreeNode.every((child) => {
                if (child.children) {
                    flattenEf(child.children)
                    return true
                } else if (child.emissionFactors) {
                    const eFforDownload = child.emissionFactors.map((eF) => ({
                        ...eF,
                        type: child.nameBelowRoot,
                        calculationMethod: child.name,
                        categoryId: child.id,
                        quantity: 0,
                        emissionFactorTableId: child.emissionFactorTableId,
                        memo: '',
                    })) as EmissionFactorForDownload[]
                    allEFFactor.push(eFforDownload)
                    return true
                }
            })
        }
        if (inputTreeState.tree.children) flattenEf(inputTreeState.tree.children)
        const allEmissionFactors = allEFFactor.flat()
        return allEmissionFactors
    }

    useEffect(() => {
        if (inputTreeState.selected.length) {
            const allEmissionFactors: EmissionFactorForDownload[] = inputTreeState.selected
                // EmissionFactor -> EmissionFactorForDownload
                .map((leaf) =>
                    leaf.emissionFactors.map((eF) => ({
                        ...eF,
                        type: leaf.nameBelowRoot,
                        calculationMethod: leaf.name,
                        categoryId: leaf.id,
                        quantity: 0,
                        emissionFactorTableId: leaf.emissionFactorTableId,
                        memo: '',
                    })),
                )
                // flatten 2-d array
                .reduce((current, prev) => {
                    return current.concat(prev)
                })

            //   add property quantity
            const allActivities = getAllActivities()
            allEmissionFactors.forEach((eF) =>
                allActivities.some((activity) => {
                    if (eF.categoryId === activity.categoryId && eF.id === activity.emissionFactorId) {
                        return (eF.quantity = activity.quantity || 0)
                    }
                }),
            )
            setEmissionFactorsForDownload(allEmissionFactors)
        } else {
            const allEmissionFactors = getAllEFFactor()
            setEmissionFactorsForDownload(allEmissionFactors)
        }
    }, [inputTreeState])

    const getAllActivities = () => {
        return Array.isArray(inputTreeState.selected)
            ? inputTreeState.selected
                  .map((leaf) =>
                      leaf.activities.map((eF) => ({
                          ...eF,
                          type: leaf.nameBelowRoot,
                          calculationMethod: leaf.name,
                          categoryId: leaf.id,
                      })),
                  )
                  .reduce((current, prev) => current.concat(prev), [])
            : []
    }

    const createData = (allCSV?: string) => {
        if (allCSV === 'All') return getAllEFFactor()
        else if (allCSV === 'All-node-select') return emissionFactorsForDownload

        const allActivities = getAllActivities()

        const selectedemissionFactors = emissionFactorsForDownload.filter((eF) =>
            allActivities.some((activity) => {
                if (eF.categoryId === activity.categoryId) {
                    return eF.id === activity.emissionFactorId
                }
            }),
        )
        return selectedemissionFactors
    }

    const handleFileDownload = (allCSV?: string) => {
        if (emissionFactorsForDownload.length) {
            const data = createData(allCSV)
            const csvText = emissionFactorsToCsvContent(data)
            const unicodeList = []
            for (let i = 0; i < csvText.length; i += 1) {
                unicodeList.push(csvText.charCodeAt(i))
            }

            // https://qiita.com/fumihiko-hidaka/items/1fb8933072db76214079
            // 変換処理の実施
            const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const writeData = new Blob([uInt8List], {
                type: 'text/csv;charset=SHIFT_JIS',
            })

            // // ダウンロード実行
            const fileName = `scope${props?.scopeNumber}-${allCSV === 'All' ? 'all' : 'select'}-input.csv`
            FileSaver.saveAs(writeData, fileName)
        }

        function emissionFactorsToCsvContent(emissionFactors: Array<EmissionFactorForDownload>): string {
            const data = emissionFactors.map((eF) => {
                const nameArr = emissionFactorTableNodes
                    .find((efn) => efn.id === eF.categoryId && efn.emissionFactorTableId === eF.emissionFactorTableId)
                    ?.parent?.split('>')
                let calculationMethod = eF.calculationMethod
                if (nameArr && nameArr.length > 1) {
                    nameArr.shift()
                    const name = nameArr
                        .map((name) => name)
                        .toString()
                        .replaceAll(',', ' > ')
                    calculationMethod = `${name} > ${calculationMethod}`
                }
                const activity = inputTreeState.selected.map((leaf) => {
                    return leaf.activities.find((a) => eF.categoryId === leaf.id && a.emissionFactorId == eF.id)
                }).find((a) => a != null)
                return {
                    分類: eF.type,
                    算定方法: calculationMethod,
                    入力項目: eF.name,
                    活動量: eF.quantity || 0,
                    単位: eF.unit,
                    排出係数ID: eF.id,
                    カテゴリーID: eF.categoryId,
                    メモ: activity?.memo != null ? activity.memo.replace(/\n/g, "\\n") : ''
                }
            })
            return Papa.unparse(data)
        }
    }
    const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            rowRadioButtonsGroup: HTMLInputElement
        }
        handleFileDownload(elements?.rowRadioButtonsGroup?.value || 'All')
        setShowModal(false)
    }

    return (
        // https://stackoverflow.com/questions/5369954/why-is-the-parent-div-height-zero-when-it-has-floated-children
        <div className={classes.container}>
            <div className={classes.csvWrapper}>
                <div className={classes.csvTitle}>CSVフォーマットをダウンロードする</div>
                <Line />
                <PrimaryButton
                    startIcon={<DownloadFileIcon />}
                    style={{ marginTop: 20, width: 164, height: 35, fontSize: 14, fontWeight: 300 }}
                    onClick={() => {
                        if (inputTreeState.selected.length) {
                            setShowModal(true)
                        } else {
                            handleFileDownload('All')
                        }
                    }}
                >
                    ダウンロード
                </PrimaryButton>
            </div>
            <div className={classes.csvWrapper}>
                {/* // https://mui.com/components/buttons/#upload-button */}
                <div className={classes.csvTitle}>CSVをアップロードする</div>
                <Line />
                <label htmlFor="item-selector-csv-handler-upload" onChange={handleFileUpload}>
                    <Input accept="text/csv" type="file" id="item-selector-csv-handler-upload" />
                    <OutlineButton
                        startIcon={<UploadFileIcon />}
                        style={{ marginTop: 20, width: 164, height: 35, fontSize: 14, fontWeight: 300 }}
                        component="span"
                    >
                        アップロード
                    </OutlineButton>
                </label>
            </div>
            <CustomModal
                title="CSVフォーマットをダウンロード"
                open={showModal}
                onClose={() => setShowModal(false)}
                width={600}
            >
                <form onSubmit={handleSubmitForm}>
                    <div
                        style={{
                            margin: '20px 0',
                            padding: '20px 0',
                            textAlign: 'center',
                        }}
                    >
                        <p
                            style={{
                                fontSize: 14,
                                color: '#222222',
                                margin: '0 auto 20px auto',
                            }}
                        >
                            ダウンロードするフォーマットを選択してください。
                        </p>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="rowRadioButtonsGroup"
                                id="rowRadioButtonsGroup"
                                style={{ display: 'flex', flexDirection: 'column', columnGap: 30 }}
                            >
                                <FormControlLabel
                                    value="All"
                                    control={<Radio color="primary" className="radio" />}
                                    label="全ての算定方法"
                                    style={{ margin: 0 }}
                                />
                                <FormControlLabel
                                    value="All-node-select"
                                    control={<Radio color="primary" className="radio" />}
                                    label="選択済みの算定方法"
                                    style={{ margin: 0 }}
                                />
                                {getAllActivities()?.length > 0 && (
                                    <FormControlLabel
                                        value="emission-factor-selected"
                                        control={<Radio color="primary" className="radio" />}
                                        label="選択済みの入力項目"
                                        style={{ margin: 0 }}
                                    />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={classes.formBtnGroup}>
                        <OutlineButton
                            style={{ width: 120, height: 35, fontSize: 14 }}
                            onClick={() => setShowModal(false)}
                        >
                            キャンセル
                        </OutlineButton>
                        <PrimaryButton type="submit" width={120} height={35} fontSize={14}>
                            ダウンロード
                        </PrimaryButton>
                    </div>
                </form>
            </CustomModal>
        </div>
    )
}
