import CategorySelector from '@/components/organisms/CategorySelector'
import ItemSelector from '@/components/template/ItemSelector'
import { withLayout } from '@/components/template/Layout'
import SimpleTabs from '@/components/template/SimpleTabs'
import { emissionFactorApi, InputStatus, makeMonth, siteActivityApi, siteSubmissionApi } from '@/ghgApi'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Activity, CalculationMethodTreeNode, EmissionFactor, ScopeCategory, Site } from '@/openapi/api'
import { InputTreeNode } from '@/utils/tree'
import { InputDate } from '@/zustand/slice/inputSlice'
import useStore from '@/zustand/sotre'
import { Typography } from '@material-ui/core'
import React, { CSSProperties, useEffect, useMemo, useState } from 'react'

const cssFixedTab: CSSProperties = {
    position: 'fixed',
    top: 80,
    zIndex: 1,
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 1600,
}

const _InputScopes = () => {
    const { storeState, inputScopeState, setInputScopeState, disabledScope3, scopeData: scopes } = useStore()
    const [selectedSite, setSelectedSite] = useState<Site>()
    const [scope3InputState, setScope3InputState] = useState<InputStatus | null>(null)
    const [currentTime] = useStateWithSessionStorage<InputDate | null>(SessionStorageKey.DATA_INPUT_DATE, null)
    const siteId = useMemo(() => storeState.selectedSite?.id, [storeState.selectedSite?.id])
    const [activities, setActivities] = useState<Activity[]>()
    const [emissionFactors, setEmissionFactor] = useState<EmissionFactor[]>()

    const getAllActivities = async (categories: ScopeCategory[], siteId: number, month: string) => {
        const res = await Promise.all(
            categories.map((category) => {
                return siteActivityApi.getSiteActivities(siteId, month, category.id)
            }),
        )
        return res.map((r) => r.data.activities).flat()
    }

    const getAllEmissionFactors = async (categories: ScopeCategory[]) => {
        const time = currentTime || undefined
        const calculationMethodTreeNode: CalculationMethodTreeNode[] = []
        const flattenChild = (node: CalculationMethodTreeNode[]) => {
            node.every((n) => {
                if (n.children) {
                    flattenChild(n.children)
                    return true
                } else {
                    calculationMethodTreeNode.push(n)
                    return true
                }
            })
        }
        flattenChild(categories.map((c) => c.calculationMethodTree) as CalculationMethodTreeNode[])
        const res = await Promise.all(
            calculationMethodTreeNode.map((node) => {
                if (time) {
                    const isGetDataPrev = [1, 2].includes(time.month) && node.emissionFactorTableId !== 2
                    const isGetDataPrevEqualID2 = [1, 2, 3].includes(time.month) && node.emissionFactorTableId === 2
                    const year = isGetDataPrev || isGetDataPrevEqualID2 ? time.year - 1 : time.year
                    if (node.emissionFactorTableId)
                        return emissionFactorApi.getEmissionFactorTable(node.emissionFactorTableId, year)
                }
            }),
        )
        return res.map((r) => r?.data).flatMap((d) => d?.emissionFactors)
    }

    useEffect(() => {
        if (!siteId) return
        const fetchData = async () => {
            try {
                const month = makeMonth(
                    currentTime?.year || inputScopeState.date.year,
                    currentTime?.month || inputScopeState.date.month,
                )
                const categories: ScopeCategory[] = scopes.map((scope) => scope.categories).flat()
                const allEmissionFactor = await getAllEmissionFactors(categories)
                const allActivities = await getAllActivities(categories, siteId, month)
                setActivities(allActivities)
                setEmissionFactor(allEmissionFactor as EmissionFactor[])
            } catch (err) {
                console.warn('ScopesAndCategoriesApi.getScopes:', err)
            }
        }
        fetchData()
    }, [siteId, scopes])

    // scope3Categories input stateを設定
    useEffect(() => {
        const selectedSite = storeState.selectedSite
        if (selectedSite && inputScopeState.date) {
            siteSubmissionApi.getSiteSubmissions(selectedSite.id).then((res) => {
                // Previous month
                let year = inputScopeState.date.year
                let month = inputScopeState.date.month - 1
                if (month == 0) {
                    year = year - 1
                    month = 12
                }
                let monthString = `${year}-${String(month).padStart(2, '0')}`

                for (const categoryId in inputScopeState.inputStatus) {
                    inputScopeState.inputStatus[categoryId] = null
                }

                if (res.data.submissions.length) {
                    let submission = res.data.submissions.find((s) => s.month == monthString)
                    if (submission) {
                        for (const categoryId in inputScopeState.inputStatus) {
                            inputScopeState.inputStatus[categoryId] = InputStatus.NOT_NEEDED
                            const submissionCategory = submission.categories.find((c) => c.id === Number(categoryId))
                            if (submissionCategory) inputScopeState.inputStatus[categoryId] = null
                        }
                    }

                    // This month
                    year = inputScopeState.date.year
                    month = inputScopeState.date.month
                    monthString = `${year}-${String(month).padStart(2, '0')}`

                    submission = res.data.submissions.find((s) => s.month == monthString)
                    if (submission) {
                        for (const categoryId in inputScopeState.inputStatus) {
                            const submissionCategory = submission.categories.find((c) => c.id === Number(categoryId))
                            if (submissionCategory) {
                                inputScopeState.inputStatus[categoryId] = submissionCategory.status as InputStatus | any
                            }
                        }
                    }
                }

                setInputScopeState({
                    ...inputScopeState,
                    inputStatus: inputScopeState.inputStatus,
                })
            })
        }
    }, [selectedSite])

    useEffect(() => {
        if (storeState.selectedSite) {
            setSelectedSite(storeState.selectedSite)
        }
    }, [storeState.selectedSite])

    useEffect(() => {
        let nextStatus = null
        const scope3CategoriesInputState = Object.values(inputScopeState.inputStatus).filter((_, index) => index > 1)
        if (
            scope3CategoriesInputState.some(
                (status) => status === InputStatus.DONE || status === InputStatus.IN_PROGRESS,
            )
        )
            nextStatus = InputStatus.DONE
        if (scope3CategoriesInputState.every((status) => status === InputStatus.NOT_NEEDED))
            nextStatus = InputStatus.NOT_NEEDED
        setScope3InputState(nextStatus)
    }, [inputScopeState])

    if (scopes && activities && emissionFactors) {
        const time = currentTime || undefined
        const scope1Props = {
            tree: new InputTreeNode(scopes[0].categories[0].calculationMethodTree as CalculationMethodTreeNode, time),
            categoryId: scopes[0].categories[0].id,
            activities: activities,
            emissionFactors: emissionFactors,
            setActivities: setActivities,
            scopeNumber: 1,
        }
        const scope2Props = {
            tree: new InputTreeNode(scopes[1].categories[0].calculationMethodTree as CalculationMethodTreeNode, time),
            categoryId: scopes[1].categories[0].id,
            activities: activities,
            emissionFactors: emissionFactors,
            setActivities: setActivities,
            scopeNumber: 2,
        }
        const scope3Props = {
            categories: scopes[2].categories,
            activities: activities,
            emissionFactors: emissionFactors,
            setActivities: setActivities,
            scopeNumber: 3,
        }

        return (
            <main>
                <title>データ入力｜ScopeX</title>
                <div>
                    <SimpleTabs
                        tabs={[
                            {
                                label: 'Scope 1',
                                content: <ItemSelector {...scope1Props} />,
                                status: inputScopeState.inputStatus[1],
                            },
                            {
                                label: 'Scope 2',
                                content: <ItemSelector {...scope2Props} />,
                                status: inputScopeState.inputStatus[2],
                            },
                            {
                                label: 'Scope 3',
                                content: <CategorySelector {...scope3Props} />,
                                status: scope3InputState,
                                disabled: disabledScope3,
                            },
                        ]}
                        value={inputScopeState.tabValue}
                        tabFixed={cssFixedTab}
                        handleChange={(val: number) => {
                            setInputScopeState({ ...inputScopeState, tabValue: val })
                        }}
                    />
                </div>
            </main>
        )
    } else {
        return (
            <div style={{ marginTop: 20 }}>
                <SimpleTabs
                    tabs={[
                        { label: 'Scope 1', content: <Typography>loading...</Typography> },
                        { label: 'Scope 2', content: <Typography>loading...</Typography> },
                        { label: 'Scope 3', content: <Typography>loading...</Typography> },
                    ]}
                    value={inputScopeState.tabValue}
                    tabFixed={cssFixedTab}
                />
            </div>
        )
    }
}

export default withLayout(_InputScopes)
