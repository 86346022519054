import Bubble from '@/components/atoms/Bubble'
import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import StyledCheckboxWithLabel from '@/components/atoms/Checkbox'
import CustomModal from '@/components/atoms/CustomModal'
import SearchBar from '@/components/atoms/SearchBar'
import CustomPagination from '@/components/molecules/Pagination'
import { useInputTreeContext } from '@/contexts/InputTree'
import { EmissionFactor } from '@/openapi'
import theme from '@/theme'
import { InputTreeLeafNode } from '@/utils/tree'
import { makeStyles } from '@material-ui/core'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ModalContent = styled.div`
    padding: 0 10px;
`

const VerticalCenter = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
`
const DescText = styled.span`
    font-size: 14px;
    font-family: ${theme.typography.fontFamily};
    color: #000;
`
const useStyle = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 40,
        marginTop: 30,
    },
    link: {
        display: 'block',
        marginTop: 10,
        fontSize: 14,
        fontWeight: 300,
        color: theme.colors.primaryN,
    },
})

export default function SearchModal(props: { leaf: InputTreeLeafNode; title: string }) {
    const { inputTreeState, setInputTreeState } = useInputTreeContext()
    const [searchString, setSearchString] = React.useState('')
    const [displayIds, setDisplayIds] = useState<InputTreeLeafNode['selectedEmissionFactorIds']>(
        props.leaf.selectedEmissionFactorIds,
    )
    const [selectedEmissionFactorIds, setSelectedEmissionFactorIds] = useState<
        InputTreeLeafNode['selectedEmissionFactorIds']
    >(props.leaf.selectedEmissionFactorIds)
    const [page, setPage] = useState(1)
    const classes = useStyle()

    const itemsPerPage = 20

    useEffect(() => {
        setDisplayIds(props.leaf.selectedEmissionFactorIds)
        setSelectedEmissionFactorIds(props.leaf.selectedEmissionFactorIds)
    }, [props.leaf])

    useEffect(() => {
        setSelectedEmissionFactorIds(props.leaf.selectedEmissionFactorIds)
    }, [props.leaf.activities])

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setSearchString('')
        setPage(1)
    }
    const handleClear = () => {
        setSelectedEmissionFactorIds(new Set())
    }
    const handleConfirm = () => {
        props.leaf.selectedEmissionFactorIds = selectedEmissionFactorIds
        props.leaf.activities = props.leaf.activities.filter((a) => selectedEmissionFactorIds.has(a.emissionFactorId))

        // 新しいActivityを追加
        const leafActivityEfIds = new Set(props.leaf.activities.map((a) => a.emissionFactorId))
        Array.from(selectedEmissionFactorIds)
            .filter((id) => !leafActivityEfIds.has(id))
            .forEach((id) => {
                props.leaf.activities.push({
                    categoryEmissionFactorTableId: props.leaf.id,
                    emissionFactorId: id,
                    quantity: null,
                })
            })
        setInputTreeState({ ...inputTreeState, tree: props.leaf.root, complete: props.leaf.root.isComplete })
        handleClose()
    }

    useEffect(
        debounce(() => {
            const nextDisplayIds = new Set(
                props.leaf.emissionFactors.filter((eF) => eF.name.includes(searchString)).map((eF) => eF.id),
            )
            setDisplayIds(nextDisplayIds)
            setPage(1)
        }, 250),
        [searchString],
    )

    const handleEFChange = (eF: EmissionFactor) => {
        if (selectedEmissionFactorIds.has(eF.id)) {
            selectedEmissionFactorIds.delete(eF.id)
        } else {
            selectedEmissionFactorIds.add(eF.id)
        }
        setSelectedEmissionFactorIds(new Set(selectedEmissionFactorIds))
    }

    return (
        <div>
            <PrimaryButton onClick={handleOpen} style={{ width: 160, height: 35, fontSize: 14 }}>
                項目を選択する
            </PrimaryButton>
            <CustomModal
                title={props.title || props.leaf.title.replace('-', '>')}
                width={1200}
                minHeight={620}
                open={open}
                onClose={handleClose}
                aria-labelledby="search-modal-title"
                aria-describedby="search-modal-description"
            >
                <ModalContent>
                    <VerticalCenter>
                        <div style={{ color: theme.colors.greyText }}>
                            <DescText>入力する項目にチェックを入れてください。</DescText>
                            {props.leaf.modalLinks &&
                                props.leaf.modalLinks.map((link, i) => (
                                    <a key={i} className={classes.link} href={link.url} target="_blank">
                                        {link.text}
                                    </a>
                                ))}
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <SearchBar
                                width={273}
                                value={searchString}
                                setValue={setSearchString}
                                placeholder="項目を検索"
                            />
                        </div>
                    </VerticalCenter>
                    {/* TODO: tobe fix borderRadius when UI is updated */}
                    <div style={{ padding: '0 20px' }}>
                        <Bubble style={{ position: 'relative', minHeight: 370, borderRadius: 0, paddingBottom: 50 }}>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                }}
                            >
                                {props.leaf.emissionFactors &&
                                    props.leaf.emissionFactors
                                        .filter((eF) => displayIds.has(eF.id))
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((eF) => (
                                            <span key={`${props.leaf.id}-${eF.id}`}>
                                                <StyledCheckboxWithLabel
                                                    checkboxProps={{
                                                        name: eF.name,
                                                        checked: selectedEmissionFactorIds.has(eF.id),
                                                        onChange: () => handleEFChange(eF),
                                                    }}
                                                />
                                            </span>
                                        ))}
                            </div>
                            <div
                                className="display-flex justify-end "
                                style={{ position: 'absolute', right: 10, bottom: 10 }}
                            >
                                <OutlineButton
                                    style={{
                                        width: 134,
                                        height: 38,
                                        fontSize: 16,
                                    }}
                                    onClick={handleClear}
                                >
                                    全て選択解除
                                </OutlineButton>
                            </div>
                        </Bubble>
                    </div>
                    {displayIds.size > itemsPerPage ? (
                        <div className={classes.pagination}>
                            <CustomPagination
                                total={displayIds.size}
                                page={page}
                                setPage={setPage}
                                rowPerPage={itemsPerPage}
                            />
                        </div>
                    ) : (
                        <div style={{ height: 35 }}></div>
                    )}
                    <div className={classes.btnGroup}>
                        <OutlineButton style={{ width: 112, height: 35, fontSize: 14 }} onClick={handleClose}>
                            戻る
                        </OutlineButton>
                        <PrimaryButton width={112} height={35} fontSize={14} onClick={handleConfirm}>
                            決定する
                        </PrimaryButton>
                    </div>
                </ModalContent>
            </CustomModal>
        </div>
    )
}
